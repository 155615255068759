.offcanvas {
  background: var(--#{$prefix}card-bg);
}

.offcanvas-header {
  border-bottom: 1px solid $border-color-transparent;
}

.offcanvas-footer {
  padding: $offcanvas-padding-y $offcanvas-padding-x;
}

.offcanvas-title {
  font-size: $h3-font-size;
  font-weight: $font-weight-medium;
  line-height: 1.5rem;
}

.offcanvas-narrow {
  width: 20rem;
}