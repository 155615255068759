.table {
  thead {
    th {
      color: $table-th-color;
      background: $table-th-bg;
      @include subheader;
      padding-top: $table-th-padding-y;
      padding-bottom: $table-th-padding-y;
      white-space: nowrap;

      @media print {
        background: transparent;
      }
    }
  }

  > :not(:first-child) {
    border-top-width: $table-border-width;
  }
}

.table-responsive {
  .table {
    margin-bottom: 0;
  }
}

.table-transparent {
  thead {
    th {
      background: transparent;
    }
  }
}

.table-nowrap {
  > :not(caption) > * > * {
    white-space: nowrap;
  }
}

.table-vcenter {
  > :not(caption) > * > * {
    vertical-align: middle;
  }
}

.table-center {
  > :not(caption) > * > * {
    text-align: center;
  }
}

.td-truncate {
  max-width: 1px;
  width: 100%;
}

.table-mobile {
  @each $breakpoint, $breakpoint-max-widthin in $grid-breakpoints {
    &#{breakpoint-infix($breakpoint)} {
      @include media-breakpoint-down($breakpoint) {
        display: block;

        thead {
          display: none;
        }

        tbody,
        tr {
          display: flex;
          flex-direction: column;
        }

        td {
          display: block;
          padding: $table-cell-padding-x $table-cell-padding-y !important;
          border: none;
          color: $body-color !important;

          &[data-label] {
            &:before {
              @include subheader;
              content: attr(data-label);
              display: block;
            }
          }
        }

        tr {
          border-bottom: 1px solid $table-border-color;
        }

        .btn {
          display: block;
        }
      }
    }
  }
}

.table-sort {
  font: inherit;
  color: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  border: 0;
  background: inherit;
  display: block;
  width: 100%;
  text-align: inherit;
  @include transition(color $transition-time);

  margin: (-$table-th-padding-y) (-$table-th-padding-x);
  padding: $table-th-padding-y $table-th-padding-x;

  &:hover,
  &.asc,
  &.desc {
    color: $body-color;
  }

  &:after,
  &.asc:after,
  &.desc:after {
    content: '';
    display: inline-flex;
    width: 1rem;
    height: 1rem;
    vertical-align: bottom;
    background: $table-sort-bg-image no-repeat center;
    opacity: .2;
  }

  &.asc:after {
    background: $table-sort-desc-bg-image no-repeat center;
    opacity: 1;
  }

  &.desc:after {
    background: $table-sort-asc-bg-image no-repeat center;
    opacity: 1;
  }
}