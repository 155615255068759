.img-responsive {
  background: no-repeat center/cover;
  padding-top: percentage(.75);
}

@each $key, $ratio in $aspect-ratios {
  .img-responsive-#{$key} {
    padding-top: $ratio;
  }
}
