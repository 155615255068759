// stylelint-disable declaration-no-important

// All colors
@each $color, $value in $theme-colors {
  .bg-#{"" + $color} {
    --#{$prefix}bg-opacity: 1;
    background-color: rgba(var(--#{$prefix}#{$color}-rgb), var(--#{$prefix}bg-opacity)) !important;
  }

  .bg-#{"" + $color}-lt {
    color: $value !important;
    background: theme-color-lighter($value, true) !important;
  }
}

@each $color, $value in $theme-colors {
  .text-#{"" + $color} {
    color: var(--#{$prefix}#{$color}) !important;
  }
}
