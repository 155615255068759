.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding: 3rem;
  }
}

.empty-icon {
  margin: 0 0 1rem;
  width: 3rem;
  height: 3rem;
  line-height: 1;
  color: $text-muted;

  svg {
    width: 100%;
    height: 100%;
  }
}

.empty-img {
  margin: 0 0 2rem;
  line-height: 1;

  img {
    height: 8rem;
    width: auto;
  }
}

.empty-header {
  margin: 0 0 1rem;
  font-size: 4rem;
  font-weight: $font-weight-light;
  line-height: 1;
  color: $text-muted;
}

.empty-title {
  font-size: $h2-font-size;
  line-height: $h2-line-height;
  font-weight: $headings-font-weight;
}

.empty-title,
.empty-subtitle {
  margin: 0 0 .5rem;
}

.empty-action {
  margin-top: 1.5rem;
}


.empty-bordered {
  border: 1px solid $border-color;
  border-radius: $border-radius;
}
