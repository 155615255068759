@mixin caret($direction: down) {
  $selector: 'after';

  @if $direction == 'left' {
    $selector: 'before'
  }

  &:#{$selector} {
    content: "";
    display: inline-block;
    vertical-align: $caret-vertical-align;
    width: $caret-width;
    height: $caret-width;
    border-bottom: 1px solid;
    border-left: 1px solid;
    margin-right: .1em;

    @if $direction != 'left' {
      margin-left: $caret-spacing;
    } @else {
      margin-right: $caret-spacing;
    }

    @if $direction == down {
      transform: rotate(-45deg);
    } @else if $direction == up {
      transform: rotate(135deg);
    } @else if $direction == end {
      transform: rotate(-135deg);
    } @else {
      transform: rotate(45deg);
    }
  }

  @if $direction == 'left' {
    &:after {
      content: none;
    }
  }
}

@mixin alert-variant(
  $background: null,
  $border: null,
  $color: null
) {
  // Override bootstrap core
}

//@mixin button-variant(
//  $background: null,
//  $border: null,
//  $color: null,
//  $hover-background: null,
//  $hover-border: null,
//  $hover-color: null,
//  $active-background: null,
//  $active-border: null,
//  $active-color: null,
//  $disabled-background: null,
//  $disabled-border: null,
//  $disabled-color: null
//) {
//  // Override bootstrap core
//}
//
//@mixin button-outline-variant(
//  $color: null,
//  $color-hover: null,
//  $active-background: null,
//  $active-border: null,
//  $active-color: null
//) {
//  // Override bootstrap core
//}