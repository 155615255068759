.accordion {
 --#{$prefix}accordion-color: #{$accordion-color};
}

.accordion-button {
  &:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
  }

  &:after {
    opacity: $text-muted-opacity;
  }

  &:not(.collapsed) {
    font-weight: $font-weight-bold;
    border-bottom-color: transparent;
    box-shadow: none;

    &:after {
      opacity: 1;
    }
  }
}