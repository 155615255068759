/*
Form check
 */
.form-check {
  user-select: none;

  &.form-check-highlight .form-check-input:not(:checked) ~ .form-check-label {
    opacity: $text-muted-opacity;
  }
}

.form-check-input {
  background-size: $form-check-input-width;
  margin-top: ($form-check-min-height - $form-check-input-width) * .5;

  .form-switch & {
    @include transition(background-color$transition-time, background-position $transition-time);
  }
}

.form-check-label {
  display: block;

  &.required {
    &:after {
      content: "*";
      margin-left: .25rem;
      color: $red;
    }
  }
}

.form-check-description {
  display: block;
  color: $text-muted;
  font-size: $h5-font-size;
  margin-top: .25rem;
}


.form-check-single {
  margin: 0;

  .form-check-input {
    margin: 0;
  }
}

/*
Form switch
 */
.form-switch {
  .form-check-input {
    height: $form-switch-height;
    margin-top: ($form-check-min-height - $form-switch-height) * .5;
  }
}

.form-switch-lg {
  .form-check-input {
    height: 1.5rem;
    width: 2.75rem;
    background-size: 1.5rem;
  }
}