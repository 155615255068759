.bg-white-overlay {
  color: $white;
  background-color: rgba($light, .24);
}

.bg-dark-overlay {
  color: $white;
  background-color: rgba($dark, .24);
}

.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
