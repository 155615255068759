.nav-vertical {
  &,
  .nav {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .nav {
    margin-left: 1.25rem;
    border-left: 1px solid var(--#{$prefix}border-color);
    padding-left: .5rem;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    font-weight: $font-weight-bold;
  }

  &.nav-pills {
    margin: 0 (-$nav-link-padding-x);
  }
}

//
// Nav bordered
//
.nav-bordered {
  border-bottom: $nav-bordered-border-width solid $nav-bordered-border-color;

  .nav-item {
    + .nav-item {
      margin-left: $nav-bordered-margin-x;
    }
  }

  .nav-link {
    padding-left: 0;
    padding-right: 0;
    margin: 0 0 (- $nav-bordered-border-width );
    border: 0;
    border-bottom: $nav-bordered-link-active-border-width solid transparent;
    color: $text-muted;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-bordered-link-active-color;
    border-color: $nav-bordered-link-active-border-color;
  }

}

.nav-tabs-alt {
  .nav-link {
    font-size: 13px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }
}

.nav-link {
  display: flex;
  @include transition(color $transition-time);
  align-items: center;
}

.nav-link-toggle {
  margin-left: auto;
  padding: 0 .25rem;
  @include transition(transform $transition-time);
  @include caret();

  &:after {
    margin: 0;
  }

  @at-root .nav-link[aria-expanded="true"] & {
    transform: rotate(180deg);
  }
}

.nav-link-icon {
  width: 1.5rem;
  height: $icon-size;
  margin-right: .25rem;
  opacity: $text-muted-opacity;

  svg {
    display: block;
    height: 100%;
  }
}