/*
Color Input
 */
.form-colorinput {
  position: relative;
  display: inline-block;
  margin: 0;
  line-height: 1;
  cursor: pointer;
}

.form-colorinput-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.form-colorinput-color {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  color: $white;
  border: 1px solid $border-color-transparent;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: no-repeat center center/$form-check-input-checked-bg-size;
    background-image: escape-svg($form-check-input-checked-bg-image);
    opacity: 0;
    @include transition(opacity $transition-time);

    .form-colorinput-input:checked ~ & {
      opacity: 1;
    }
  }

  .form-colorinput-input:focus ~ & {
    border-color: $primary;
    box-shadow: $input-btn-focus-box-shadow;
  }

  .form-colorinput-light & {
    &:before {
      background-image: escape-svg($form-check-input-checked-bg-image-dark);
    }
  }
}

