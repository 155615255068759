.badge {
  justify-content: center;
  align-items: center;
  background: $gray-500;
  overflow: hidden;
  user-select: none;
  border: 1px solid transparent;
  min-width: ($badge-padding-y * 2 ) + (divide($badge-font-size, 100%) * 1em);
  font-weight: $headings-font-weight;
  letter-spacing: .04em;
  vertical-align: bottom;

  @at-root a#{&} {
    color: $white;
  }

  &:empty {
    display: inline-block;
    width: $badge-empty-size;
    height: $badge-empty-size;
    min-width: 0;
    min-height: auto;
    padding: 0;
    border-radius: $border-radius-pill;
    vertical-align: baseline;
  }

  .avatar {
    box-sizing: content-box;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 .5rem 0 -.5rem;
  }

  .icon {
    width: 1em;
    height: 1em;
    font-size: 1rem;
    stroke-width: 2;
  }
}

.badge-sm {
  font-size: $h6-font-size;
  line-height: $h6-line-height;
  padding: 0 .25rem;
}

// bordered
.badge-outline {
  background-color: transparent;
  border: 1px solid currentColor;
}

.badge-pill {
  border-radius: $border-radius-pill;
}