.page {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
}

.page-center {
  justify-content: center;
}

.page-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;

  @media print {
    margin: 0 !important;
  }
}

.page-wrapper-full {
  .page-body:first-child {
    margin: 0;
    border-top: 0;
  }
}

// Content body
.page-body {
  margin-top: $content-padding-y;
  margin-bottom: $content-padding-y;
}

.page-body-card {
  background: $card-bg;
  border-top: 1px solid $card-border-color;
  padding: $content-padding-y 0;
  margin-bottom: 0;
  flex: 1;

  .page-body ~ & {
    margin-top: 0;
  }
}

.page-cover {
  background: no-repeat center/cover;
  min-height: 9rem;

  @include media-breakpoint-up(md) {
    min-height: 12rem;
  }

  @include media-breakpoint-up(lg) {
    min-height: 15rem;
  }
}

.page-cover-overlay {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: $overlay-gradient;
  }
}


.page-header {
  display: flex;
  flex-wrap: wrap;
  min-height: 2.25rem;
  flex-direction: column;
  justify-content: center;

  .page-wrapper & {
    margin: $content-padding-y 0 0;
  }
}

.page-header-border {
  border-bottom: 1px solid $border-color-transparent;
  padding-bottom: $content-padding-y;
}

.page-pretitle {
  @include subheader;
}

.page-title {
  margin: 0;
  font-size: $h2-font-size;
  line-height: $h2-line-height;
  font-weight: $font-weight-bold;
  color: inherit;
  display: flex;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: .25rem;
  }
}

.page-title-lg {
  font-size: $h1-font-size;
  line-height: $h1-line-height;
}

.page-subtitle {
  margin-top: .25rem;
  color: $text-muted;
}

//
// Page tabs
//
.page-tabs {
  margin-top: .5rem;
  position: relative;
}

.page-header-tabs {
  .nav-bordered {
    border: 0;
  }

  + .page-body-card {
    margin-top: 0;
  }
}