.alert {
  --#{$prefix}alert-color: #{$text-muted};
  background: $white;
  border: $alert-border-width solid $alert-border-color;
  border-left: .25rem solid var(--#{$prefix}alert-color);
  box-shadow: $alert-shadow;

  >:last-child {
    margin-bottom: 0;
  }
}

.alert-important {
  border-color: transparent;
  background: var(--#{$prefix}alert-color);
  color: #fff;

  .alert-icon,
  .alert-link {
    color: inherit;
  }

  .alert-link:hover {
    color: inherit;
  }
}

.alert-link, {
  &,
  &:hover {
    color: var(--#{$prefix}alert-color);
  }
}

@each $state, $value in $theme-colors {
  .alert-#{$state} {
    --#{$prefix}alert-color: #{$value};
  }
}

.alert-icon {
  color: var(--#{$prefix}alert-color);
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin: -.125rem $alert-padding-x -.125rem 0;
}

.alert-title {
  font-size: $h4-font-size;
  line-height: $h4-line-height;
  font-weight: $font-weight-bold;
  margin-bottom: .25rem;
  color: var(--#{$prefix}alert-color);
}