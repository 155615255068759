.toast {
  background: $white;
  border: $alert-border-width solid $alert-border-color;
  border-left: .25rem solid var(--#{$prefix}toast-color);
  box-shadow: $alert-shadow;

  .toast-header {
    user-select: none;
  }

  button[data-bs-dismiss="toast"] {
    outline: none;
  }
}

@each $state, $value in $theme-colors {
  .toast-#{$state} {
    --#{$prefix}toast-color: #{$value};
  }
}
