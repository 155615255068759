/**
Bootstrap color input
 */
.form-control-color {
  &::-webkit-color-swatch {
    border: none;
  }
}

/**
Remove the cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

/**
Form control dark theme fix
 */
.form-control {
  &::file-selector-button {
    background-color: var(--#{$prefix}btn-color, #{$form-file-button-bg});
  }

  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--#{$prefix}btn-color, #{$form-file-button-hover-bg});
  }
}