// stylelint-disable property-no-vendor-prefix
:root {
  font-size: 16px;
  height: 100%;

  @each $name, $color in $theme-colors {
    --#{$prefix}#{$name}: #{$color};
  }

  @each $name, $color in $theme-colors {
    --#{$prefix}#{$name}-rgb: #{to-rgb($color)};
  }

  --#{$prefix}card-bg: #{$card-bg};
  --#{$prefix}card-bg-rgb: #{to-rgb($card-bg)};

  --#{$prefix}border-color: #{$border-color};
  --#{$prefix}border-color-rgb: #{to-rgb($border-color)};

  --#{$prefix}border-color-light: #{$border-color-light};
  --#{$prefix}border-color-light-rgb: #{to-rgb($border-color-light)};
}

body {
  overflow-y: scroll;
  letter-spacing: $body-letter-spacing;
  touch-action: manipulation;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga" 0;
  position: relative;
  min-height: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media print {
    background: transparent;
  }
}

@include scrollbar;

//
// Fluid container
//
.layout-fluid {
  .container,
  [class^="container-"],
  [class*=" container-"] {
    max-width: 100%;
  }
}

//
// Boxed container
//
.layout-boxed {
  --#{$prefix}theme-boxed-border-radius: 0;
  --#{$prefix}theme-boxed-width: #{map-get($container-max-widths, xxl)};

  @include media-breakpoint-up(md) {
    background: $dark linear-gradient(to right, rgba(#fff, .1), transparent) fixed;
    padding: 1rem;
    --#{$prefix}theme-boxed-border-radius: #{$border-radius};
  }

  .page {
    margin: 0 auto;
    max-width: var(--#{$prefix}theme-boxed-width);
    border-radius: var(--#{$prefix}theme-boxed-border-radius);
    color: $body-color;

    @include media-breakpoint-up(md) {
      border: 1px solid var(--#{$prefix}border-color);
      background: var(--#{$prefix}body-bg);
    }

    > .navbar:first-child {
      border-top-left-radius: var(--#{$prefix}theme-boxed-border-radius);
      border-top-right-radius: var(--#{$prefix}theme-boxed-border-radius);
    }
  }
}